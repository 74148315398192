import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import * as turf from '@turf/turf';
import _ from 'lodash/fp';
import { usePrevious } from 'react-use';
import { PRODUCT_TYPE } from '../../constants/product';
import useWorkerCallback from '../../hooks/useWorkerCallback';
import { formatSqmArea } from '../../text';
import { textShadowStyle } from '../../utilities';
import { formatMonth } from '../../utilities/date';
import { isArrayEqual } from '../../utilities/lodash';
import { selectionToGeoJsonNoGM } from '../../workers/utilities';
import { PRODUCT_DATA } from './constants';
import { computeVisualCenter } from './geometry';
import { MapViewContext } from './mapViewContext';

let MapLabel = null;
const MAP_LABEL_OVERLAY_TYPE = {
  AREA: 'AREA',
  DATE: 'DATE',
};

/**
 * `useCoverageLabels` renders the coverage labels onto the map.
 */
const useCoverageLabels = () => {
  const { state } = useContext(MapViewContext);
  const [mapOverlays, setMapOverlays] = useState([]);
  const [newLabels, setNewLabels] = useState([]);
  const previousSelections = usePrevious(state.selections);
  const previousCoverage = usePrevious(state.coverage);

  useEffect(() => {
    (async () => {
      if (state.googleMapsScriptStatus === 'ready') {
        const tempMapLabel = await import('./mapLabel');
        MapLabel = tempMapLabel.MapLabel;
      }
    })().catch((err) => {
      console.error('Error loading MapLabel:', err);
    });
  }, [state.googleMapsScriptStatus]);

  const getCoverageLabelsWorker = useMemo(
    () =>
      new Worker(
        new URL('../../workers/getCoverageLabels.worker.js', import.meta.url)
      ),
    []
  );

  const updateCoverageLabels = useWorkerCallback(
    getCoverageLabelsWorker,
    (labels) => {
      setNewLabels(labels);
    }
  );

  const cleanUpMapOverlays = useCallback(
    (overlayType = MAP_LABEL_OVERLAY_TYPE.DATE) => {
      if (!overlayType) return;

      setMapOverlays((currentOverlays) => {
        let hasChanges = false;
        const remainingOverlays = currentOverlays.filter((row) => {
          if (overlayType === row.type) {
            if (row.overlay && row.overlay.setMap) {
              row.overlay.setMap(null);
            }
            hasChanges = true;
            return null;
          }
          return row;
        });

        return hasChanges ? remainingOverlays : currentOverlays;
      });
    },
    []
  );

  const createAreaLabelOnSelection = useCallback(() => {
    cleanUpMapOverlays(MAP_LABEL_OVERLAY_TYPE.AREA);
    if (
      state.activeSelection &&
      state.activeSelection.category_name !== PRODUCT_TYPE.UNKNOWN &&
      state.activeSelection.region &&
      state.coveragePerProduct
    ) {
      const { id, category_name } = state.activeSelection;
      const coverage = _.find(['selection_id', id])(
        state.coveragePerProduct[category_name]
      );

      if (!coverage) {
        return;
      }

      const geom = selectionToGeoJsonNoGM(state.activeSelection).geometry;
      let visualCenter = computeVisualCenter(geom);

      if (category_name === PRODUCT_TYPE.STREETSCAPE) {
        const polygons =
          coverage.geometry.coordinates &&
          coverage.geometry.coordinates.map((coords) => ({
            type: 'Polygon',
            coordinates: coords,
          }));

        const areas = polygons.map((polygon) => ({
          polygon,
          area: turf.area(turf.polygon(polygon.coordinates)),
        }));

        const { polygon: largestPolygon } = areas.reduce(
          (max, current) => (current.area > max.area ? current : max),
          { area: 0 }
        );

        // largestPolygon should not be undefined
        if (largestPolygon) {
          visualCenter = computeVisualCenter(largestPolygon);
        }
      }

      const [lng, lat] = visualCenter.coordinates;
      const fontColor = PRODUCT_DATA.entities[category_name].display_color;
      // in case coverage doesn't return an area, we're not showing the area label
      if (coverage.area_in_sqm > 0) {
        setMapOverlays((overlays) => [
          ...overlays,
          {
            type: MAP_LABEL_OVERLAY_TYPE.AREA,
            overlay: new MapLabel(
              new google.maps.LatLng(lat, lng),
              formatSqmArea(coverage.area_in_sqm),
              'transparent',
              state.map,
              fontColor,
              textShadowStyle('2px', `#FAFAFA`)
            ),
          },
        ]);
      }
    }
  }, [
    cleanUpMapOverlays,
    state.activeSelection,
    state.coveragePerProduct,
    state.map,
  ]);

  useEffect(() => {
    cleanUpMapOverlays(MAP_LABEL_OVERLAY_TYPE.DATE);
    const overlays = [];
    newLabels.forEach((label) => {
      overlays.push({
        type: MAP_LABEL_OVERLAY_TYPE.DATE,
        overlay: new MapLabel(
          new google.maps.LatLng(label.position[1], label.position[0]),
          formatMonth(label.dateAcquired * 1000),
          label.displayColor,
          state.map
        ),
      });
    });
    setMapOverlays((overlay) => [...overlay, ...overlays]);

    // On Select/Click shape show Area label
    createAreaLabelOnSelection();
  }, [cleanUpMapOverlays, createAreaLabelOnSelection, newLabels, state.map]);

  const checkAndRepositionMapLabels = useCallback(() => {
    const avoidOverlayClash = (overlays) => {
      const padding = 0.00005; // rought distance only, improvements would be great
      overlays.forEach((overlayA, i) => {
        const posA = overlayA.overlay.position;
        overlays.slice(i + 1).forEach(({ overlay: overlayB }) => {
          const posB = overlayB.position;
          if (arePositionsTooClose(posA, posB, padding)) {
            const adjustedPos = {
              lat: posB.lat() + padding,
              lng: posB.lng() + padding,
            };
            overlayB.position = new google.maps.LatLng(
              adjustedPos.lat,
              adjustedPos.lng
            );
          }
        });
      });
    };

    const arePositionsTooClose = (posA, posB, padding) => {
      return (
        Math.abs(posA.lat() - posB.lat()) < padding &&
        Math.abs(posA.lng() - posB.lng()) < padding
      );
    };

    if (mapOverlays.length > 0) {
      avoidOverlayClash(mapOverlays, state.map) || [];
    }
  }, [mapOverlays, state.map]);

  useEffect(() => {
    checkAndRepositionMapLabels();
  }, [checkAndRepositionMapLabels]);

  /**
   * Draw map labels (date indicators) whenever coverage in selections change.
   */
  useEffect(() => {
    if (!window?.Worker) {
      return;
    }

    if (!MapLabel) {
      return;
    }

    if (
      !state.selections ||
      !state.coverage ||
      (isArrayEqual(state.selections, previousSelections, ['focused']) &&
        isArrayEqual(state.coverage, previousCoverage))
    ) {
      return;
    }

    updateCoverageLabels({
      coverage: state.coverage,
      selections: state.selections,
    });
  }, [
    state.selections,
    state.coverage,
    previousSelections,
    previousCoverage,
    updateCoverageLabels,
  ]);
};

export default useCoverageLabels;
