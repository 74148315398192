const messages = {
  aerial: 'Aerial',
  streetscape: 'Streetscape',
  interior: 'Interior Tripod',
  exterior: 'Exterior Tripod',
  drone: 'Drone',
  bim_exterior: 'Exterior BIM',
  bim_interior: 'Interior BIM',
  twod_plan_exterior: 'Exterior 2D Feature Plan',
  twod_plan_interior: 'Interior 2D Feature Plan',
  bim_exterior3D: 'Exterior BIM',
  bim_interior3D: 'Interior BIM',
  twod_plan_exterior3D: 'Exterior 2D Feature Plan',
  twod_plan_interior3D: 'Interior 2D Feature Plan',
  unknown: 'Draft',
  aerial3D: 'Aerial 3D Point Cloud',
  streetscape3D: 'Streetscape 3D Point Cloud',
  interior3D: 'Interior Tripod 3D Point Cloud',
  exterior3D: 'Exterior Tripod 3D Point Cloud',
  drone3D: 'Drone 3D Point Cloud',
  unknown3D: 'Draft Shape',
  greatFrontFacadesStreetscapes: 'Great for front facades',
  greatLargeAreas: 'Great for large areas',
  greatRoofsLargeGrounds: 'Great for roofs & grounds',
  highAccuracy: 'High accuracy',
  highDetail: 'High detail',
  higherCost: 'Higher cost',
  highestAccuracy: 'Highest accuracy',
  highestCost: 'Highest cost',
  highestDetail: 'Highest detail',
  lowCost: 'Low cost',
  lowerAccuracy: 'Lower accuracy',
  lowerDetail: 'Lower detail',
  mediumAccuracy: 'Medium accuracy',
  mediumDetail: 'Medium detail',
  mostComprehensive: 'Most comprehensive',
  quickTurnaroundTime: 'Quick turnaround time',
  slowerTurnaroundTime: 'Slower turnaround time',
  slowestTurnaroundTime: 'Slowest turnaround time',
  older: 'Older',
  forPreciseExteriors: 'For precise exteriors',
  forPreciseInteriors: 'For precise interiors',
  continue: 'Continue',
  cancel: 'Cancel',
  trackYourOrder: 'This will make it easier to track your order.',
  enterProjectName: 'Enter project name',
  decreaseSelectionArea: 'Available, decrease selection area',
  increaseSelectionArea: 'Available, increase selection area',
  productDataAvailable: `{productName} 3D Data available. `,
  productDataUnavailable: `{productName} 3D Data not available. Please contact us or select an alternate data type.`,
  dragDecreaseSelectionArea: 'Drag corners to decrease selection area.',
  dragIncreaseSelectionArea: 'Drag corners to increase selection area.',
  dragModifySelectionArea: 'Drag corners to modify selection or add to order.',
  allProductUnavailable: '3D Data not available. Please contact us.',
  add3DData: 'Add 3D data from the availability list above',
  noDataAvailable: 'No data is available',
  dataAlreadyAdded: '3D Data has already been added',
  dataNotInDatabase: 'Not in database, please ',
  cartIsEmpty: 'Your Cart is empty',
  cartIsEmptyDescription1:
    'Add a Shape to the 2D Map, then assign a Database or Capture type to add to the Cart.',
  cartIsEmptyDescription2:
    'Alternatively, click “+” below to add to your Order: ',
  cartIsEmptyNoAddOnsDescription:
    'Enter a Site Address, add a Shape to the 2D Map, then assign a Database or Capture type to add to the Cart.',
  selectedDataAlreadyAdded: '{productName} 3D Data has already been added',
  contactUs: 'Contact Us',
  noFilesAvailable: 'No files available',
  profile: 'Profile',
  account: 'Account',
  logout: 'Logout',
  order3DData: 'Order 3D',
  addOrderToProject: 'Add Order',
  name: 'Name',
  email: 'Email',
  enterEmail: 'Enter email address',
  dashboard: 'Dashboard',
  order: 'Order',
  map2D: '2D Map',
  administrationMap2D: 'Admin | 2D Map',
  highResMap2D: '2D High-res Map',
  yourProjectSavedAsDraft: 'Your project will then be saved as a draft.',
  accessDraftsAnytime: 'Access your saved drafts anytime via the dashboard.',
  goToDashboard: 'Go to Dashboard',
  saveAndGoToDashboard: 'Dashboard',
  larkiURL: 'LARKI.com.au ',
  home: 'Home',
  product: 'Product',
  products: 'Products',
  pricing: 'Pricing',
  aboutUs: 'About Us',
  viewer3D: '3D Viewer',
  viewer3dUnavailable: '3D Viewer unavailable',
  project3Dviewer: '3D Viewer | {projectName}',
  returnToMap: 'Return to map',
  orderCouldNotPlaced: 'Order could not be placed',
  orderCouldNotPlacedAtTime:
    'Unfortunately, your order could not be placed at this time.',
  savedAsDraftTryAgain:
    'Your order has been saved as a draft, please try again later. We apologise for any inconvenience.',
  orderCouldNotSaved: 'Order could not be saved',
  orderCouldNotSavedAtTime:
    'Unfortunately, we could not save your order at this time.',
  productCouldNotBeAdded: 'Product could not be added',
  couldNotAddProductToOrder:
    'Unfortunately, we could not add that product to your order.',
  pleaseTryAgainApologise:
    'Please try again later. We apologise for any inconvenience.',
  submit: 'Submit',
  enterValidEmail: 'Please enter a valid email',
  forgotYourPassword: 'Forgot your password',
  passwordResetSent: 'Password reset instructions sent',
  checkInbox: "Check your inbox. We've emailed password reset instructions to:",
  haventReceivedEmail: "Haven't received an email?",
  resendEmail: 'Resend email',
  passwordHelpText:
    'Password must contain at least eight characters, including at least one number and symbol',
  confirmPassword: 'Confirm Password',
  enterValidPassword: 'Please enter a valid password',
  passwordsMustMatch: 'Your passwords must match',
  createNewPassword: 'Create new password',
  passwordChanged: 'Password changed successfully.',
  accountCreated: 'Account created',
  checkInboxSignup: "Check your inbox. We've emailed a verification link to:",
  incorrectEmail: 'Incorrect email address?',
  createAccount: 'Create account',
  password: 'Password',
  enterPassword: 'Enter password',
  highResTooltip: 'High-res 2D Map',
  undo: 'Undo',
  redo: 'Redo',
  createPassword: 'Create a password',
  createYourPasswordSuccess: 'We have successfully created your password.',
  loginToYourAccount: 'Login to your account',
  login: 'Login',
  signUp: 'Sign up',
  forgotPassword: 'Forgot Password?',
  dontHaveAccount: 'Don’t have an account yet?',
  or: 'or',
  signUpWithGoogle: 'Sign up with Google',
  testomonialBody: `LARKI 3D surveys really saved us time. All necessary
    information was collected in great detail, allowing us to work
    confidently and avoid having to return to the site to
    re-measure. LARKI 3D surveys is really a game changer.`,
  director: 'Director',
  createYourAccount: 'Create your account',
  createYourAccountBody1: 'And join thousands of property professionals',
  createYourAccountBody2: 'leveraging the power of point cloud data',
  getStarted: 'Help / Get started',
  createAccountByline: 'By creating an account, you agree to our',
  termsOfUse: 'Terms of Use',
  and: 'and',
  privacyPolicy: 'Privacy Policy',
  alreadySignedUp: 'Already signed up?',
  boolean: 'Boolean',
  welcomeToLarki: 'Welcome to LARKI',
  tellUsAboutYourself:
    'So that we can better serve your needs, tell us a bit about yourself.',
  letsBeginWithFullNameAndPhoneNumber:
    "Let's begin with your full name and phone number",
  nextStep: 'Next step',
  other: 'Other',
  propertyOwner: 'Property Owner',
  developer: 'Developer',
  architect: 'Architect',
  buildingDesigner: 'Building Designer',
  thanks: 'Thanks',
  thankYou: 'Thank you!',
  describeYourOccupation: 'How would you describe your occupation?',
  goToMyDashboard: 'Go to my dashboard',
  orderSurveyData: 'Order 3D Survey Data',
  oneLastQuestion: 'One last question',
  own3DSoftware: 'Do you use your own 3D design software?',
  fullName: 'Full name',
  phoneNumber: 'Phone number',
  pleaseSpecify: 'Please specify',
  priceEstimate: 'Price estimate',
  priceFrom: 'Price from',
  deliveryTime: 'Delivery time',
  message: 'Message',
  addMessageOptional: 'Add message (optional)',
  success: 'Success',
  passwordReset: 'Your password has been reset',
  action: 'Action',
  actions: 'Actions',
  more: 'More',
  orderHistory: 'Order History',
  noOrdersAvailable:
    "You don't currently have any orders available with status {status}",
  projectNameLength: 'Name should be {min}-{max} characters',
  projectNameAllowedChars: "Please only use letters, numbers, '-' or '_'",
  required: '*Required',
  menu: 'Menu',
  phone: 'Phone',
  company: 'Company',
  companyDetails: 'Company Details',
  occupation: 'Occupation',
  enterOccupation: 'Enter occupation',
  invalidPhone: 'Invalid phone number',
  connect: 'Connect',
  follow: 'Follow',
  copyright: '© Copyright LARKI 2021',
  customerTermsConditions: 'Customer Terms & Conditions',
  supplierTermsConditions: 'Supplier Terms & Conditions',
  leaveReview: 'Leave a review',
  blog: 'Blog',
  free: 'Free',
  projectName: 'Project name',
  siteAddress: 'Site address',
  createdDate: 'Created',
  status: 'Status',
  fileManager: 'File Manager',
  productPointCloud3DTitle:
    '{product}{extraLabel} 3D Point Cloud (for {software})',
  productGenericPointCloud3DTitle: '{product}{extraLabel} 3D Point Cloud',
  drawing2DTitle: '{tripod} 2D Feature Plan (for {software})',
  bim3DTitle: '{tripod} BIM (for {software})',
  compressed: 'Compressed',
  productPointCloud3DOrder: '{productType} 3D Point Cloud order:',
  image: 'Image',
  document: 'Document',
  great: 'Great',
  askOccupation: 'Can you tell us what your occupation is?',
  updatedDate: 'Updated',
  pendingInvite: '{user} has invited you to "{project}"',
  inviteViewProjectUsers: 'Invite/View Project Users',
  inviteProjectUser: 'Invite Project User to:',
  sendInvitation: 'Send invitation',
  projectUsers: 'Project Users',
  userAlreadyInvited: 'User already invited',
  expectedAt: 'ETA {eta}',
  removeProjectUser: 'Remove project user',
  leaveProject: 'Leave project',
  resendInvitation: 'Resend invitation',
  enterSubject: 'Enter subject',
  subject: 'Subject',
  add: 'Add',
  addNote: 'Add note',
  note: 'Note',
  enterNote: 'Enter a note',
  sendNotificationToCustomer: 'Send notifications',
  enterIDNumber: 'Pointerra ID',
  updatePointerraID: 'Update Pointerra ID',
  update: 'Update',
  uploadFiles: 'Upload files',
  upload: 'Upload',
  dropHereOr: 'Drag and drop files or %{browse}',
  browseToUpload: 'Browse to upload',
  files: 'Files',
  updateProductStatus: 'Update product / status',
  estimatedTimeOfArrival: 'Estimated Completion',
  selectDate: 'Select date',
  addNewProduct: 'Add new product',
  enterCustomStatus: 'Enter custom status',
  enterNewProduct: 'Enter new product',
  highRes2DMap: 'High-res 2D Map',
  metromapTrial: 'Trial the {product}',
  freeFor2Hours: 'free for 2 hours',
  thanksForTrial:
    'Thanks for trialing the {product}. If you’d like to subscribe, please contact LARKI for more information.',
  subscribeForUnlimited: 'Subscribe for unlimited access to {product}.',
  dropFilesToUpload: 'Drop files to upload',
  selectStatus: 'Select status',
  completedByDate: 'Completed: {completedDate}',
  completed: 'Completed',
  active: 'Active',
  draft: 'Draft',
  administration: 'Admin',
  projects: 'Projects',
  groupedAssetsHeading: `{baseMsg} {toDownload}`,
  filesToDownload: '{filePlural} to download:',
  filesPlural: ` {numberFiles, plural,
    =1 {file}
    other {files}
  }`,
  administrationFileManager: 'Admin | File Manager',
  administrationViewer3D: 'Admin | 3D Viewer',
  siteDeploymentFeeInfo:
    'Drone and Tripod Data orders require LARKI to deploy equipment and surveyors to 3D laser scan your site. A one-off Site Deployment fee is automatically added when choosing one of these data types.',
  saveAnyway: 'Save anyway',
  overwriteChanges: 'Overwrite changes?',
  overwriteRecentlyUpdatedProject:
    'The project was recently updated by another user, do you want to overwrite their changes and save anyway?',
  invalidParam: '{param} provided is not valid',
  pointerraFailure:
    '3D Viewer failed to load, please refresh the page or <link>contact us.</link>',
  pleaseEnterPassword: 'Please enter your password.',
  createPasswordDescription:
    'This will ensure you can login to your account from any device in the future. Passwords must be at least eight characters and contain at least one symbol.',
  chooseOptionsToGetStarted: 'Choose from the options below to get started.',
  signUpLoginWithGoogle: 'Sign up / login with Google',
  termsAndConditions: 'Terms & Conditions',
  acceptTermsAndConditions: "I accept LARKI's {termsConditions}",
  added: 'Added',
  availability: 'Availability',
  orderSummary: 'Order summary',
  software: 'Software',
  passwordValidation:
    'Password must be at least eight characters, contain one symbol and one number.',
  users: 'Users',
  goTo3DViewer: 'Go to 3D Viewer',
  viewOrder2DMap: 'View order on 2D Map',
  pointerraHash: 'Pointerra # update',
  notificationSent: 'Notifications sent',
  showLarkiLogs: 'Show LARKI logs',
  remove: 'Remove',
  invoice: 'Invoice',
  productData: '{product} ({area})',
  subtotal: 'SUBTOTAL',
  total: 'TOTAL',
  saveCardNextTime: 'Securely save this card for next time',
  payAmount: 'Pay {amount}',
  payNow: 'Pay now',
  nameOnCard: 'Name on card',
  addNewCard: 'Add new card',
  payByCard: 'Pay by card',
  paymentHelp: 'Having trouble making a payment? {contactUs}',
  fieldIsIncomplete: '{field} is incomplete',
  backTo2DMap: 'Back to 2D Map',
  thanksForPayment: 'Thanks for your payment!',
  receiptEmailSent: 'The receipt(s) will be emailed to you.',
  viewReceipt: 'View receipt {receiptNumber}',
  receiptNumber: 'Receipt number',
  paymentDate: 'Payment date',
  paymentMethod: 'Payment',
  myPaymentDetails: 'My payment details',
  untitled: 'Untitled',
  areYouSureYouWantToLeavePage: 'Are you sure you want to leave the page?',
  progressLost: 'Your progress will be lost if you leave',
  leavePage: 'Leave page',
  stayOnPage: 'Stay on page',
  ok: 'OK',
  orderNotComplete:
    'Your order is not complete until payment has been processed.',
  membership: 'Membership',
  billingCycle: 'Billing cycle',
  contractRenewalDate: 'Contract Renewal Date',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  noSubscriptionFound: 'No subscription found',
  selectAreaOnMap: 'Use tools to select an area on the map',
  notAvailable: 'N/A',
  toBeConfirmed: 'TBC',
  asSoonAsPossible: 'ASAP',
  plusTax: '{amount} +GST',
  siteDeploymentFee: 'Site Deployment fee ',
  receiveMarketing:
    'I would like to receive LARKI updates, special offers and event invitations!',
  dataDiscount: 'Buy in-Platform Data now {icon} 10% off',
  getEstimateDataCapture: 'Get estimate for Data Capture Services',
  consultSalesDepartment: 'Consult Sales',
  orderNow: 'Order now',
  getEstimate: 'Get estimate',
  discountFirstPurchase:
    'Discount applies to your first Streetscape and/or Aerial data purchase. For a limited time only, conditions apply',
  delete: 'Delete',
  versionOutdated: 'This page has been updated. Please <link>reload</link>.',
  duplicate: 'Duplicate',
  duplicateOf: 'Duplicate of {originalItem}',
  expandPanel: 'Expand panel',
  collapsePanel: 'Collapse panel',
  search: 'Search',
  available: 'Available',
  estimateCost: 'Estimate: {cost}+GST',
  registerToViewAndPurchaseOnline:
    'Sign up for free or login to view and purchase online.',
  registerToViewAndEstimate:
    'Sign up for free or login to view and get estimate online',
  deliveredIn: 'Delivered in',
  dataCapturedProductDescription:
    'Colourised 3D Point Cloud data captured from a {product}.',
  plane: 'plane',
  car: 'car',
  captureApproximate: 'Captured',
  services: 'Services',
  offerTailoredServices:
    'We offer tailored services such as {tripod}, {drone}, {bim}, {featurePlans2D} and {more}.',
  tripod: 'Tripod',
  bim: 'BIM',
  featurePlans2D: '2D Feature Plans',
  from: 'From',
  notAvailableForAddress: 'Not available for this address.',
  enquireNowToAssist: 'Enquire now to see how we can assist.',
  enquireNow: 'Enquire now',
  notSureWhatSuitsQuestion: 'Not sure what would suit your project best?',
  weAreHereToHelpEnquireNow:
    'We’re here to help, enquire now and we’ll be in touch.',
  trialRemainder: `<trialRemainder></trialRemainder> {isDay, select,
    true {{remainder, plural,
      =1 {day}
      other {days}
    }}
    other {{remainder, plural,
      =1 {hour}
      other {hours}
    }}
  }`,
  numberOfDays: ` {daysNumber, plural,
      =1 {# day}
      other {# days}
    }`,
  numberOfHours: ` {hoursNumber, plural,
      =1 {1 hour}
      other {# hours}
    }`,
  signUpOrLoginToGetAccess:
    'Sign up for free or login to get access to 3D Data & Services',
  joinTheBestInTheIndustry:
    'Join the best in the industry, save time by doing site measurements online',
  howDoYouMeasureUp: 'How do you measure-up?...',
  signUpLogin: ' Sign up / login',
  deleteNote: 'Delete note?',
  deleteFileUploadActivity: 'Delete file upload activity?',
  deleteFile: 'Delete file?',
  deleteNoteBody:
    'This note will be removed from the timeline. This cannot be undone.',
  deleteFileUploadActivityBody:
    'This activity and associated files will be removed from the timeline. This cannot be undone.',
  deleteFileBody:
    'This file will be removed from the activity. This cannot be undone.',
  lotArea: 'Lot Area:',
  downloadItem: 'Download .{item}',
  download: 'Download',
  fileType: '{fileType} file',
  captured: 'Captured',
  price: 'Price',
  delivery: 'Delivery',
  cart: 'Cart',
  goToDashboardPleaseSignUp:
    'So we can take you to the dashboard, please sign up to LARKI',
  goToOrderPleaseSignUp:
    'So we can continue the Order for you, please sign up to LARKI',
  toContinueWithHighResMap:
    'So you can turn on the High-res 2D Map, sign up to LARKI',
  becomeLarkiMember:
    'Become a LARKI Member to get discounts & access to the Platform',
  monthlyMembership: 'Monthly Membership',
  monthEquivalent: 'month equivalent',
  essentialMembershipFeatures:
    'Discounts, High-res 2D, File Manager, 3D Viewer & more.',
  selectMembership: 'Select your Membership',
  minPriceAmount: 'Minimum contract price: {amount}',
  paidUpfrontAmount: '{months} months paid upfront: {amount}',
  learnMore: 'Learn more',
  viewLess: 'View less',
  previous: 'Previous',
  next: 'Next',
  memberDiscount: 'Member discount (30%):',
  servicesDiscount: 'Services discount (10%):',
  memberDiscountOff: 'Member discount: {percentage} off',
  totalMemberDiscount: 'Total member discount:',
  memberPrice: 'Member price',
  becomeAMember:
    'Become a Member for discounts, High-res 2D, File Manager, 3D Viewer, easy sharing & more! <b>Add Membership later, at step 2...</b>',
  youAreALarkiMember:
    'You are a LARKI Member, which gives you discounts, Platform access and more!',
  percentageSaving: '{percentage}% saving!',
  whatSoftwareWillYouUse: 'What software will you use?',
  selectSoftwareFormat:
    'Select software you will use and we’ll ensure you receive the correct file format(s).',
  comments: 'Comments',
  projectDetails: 'Project',
  confirmation: 'Confirm',
  selectPaymentMethod: 'Select payment method',
  addCard: 'Add card',
  yourSoftware: 'Your software',
  totalPrice: 'Total price',
  confirmOrder: 'Confirm your order is correct and pay',
  dontWantDiscounts: 'Don’t want discounts and access to the Platform',
  orderWithoutMembership: 'Order without Membership',
  yearlySaveDiscount: 'Yearly SAVE {discount}%',
  noThanks: 'No thanks',
  noMembership: 'No Membership',
  noMembershipFeatures:
    'I don’t want Data Discounts, High-res 2D Map, File Manager, 3D Viewer, easy sharing & more.',
  membershipPaymentFailed: 'Membership Payment Failed!',
  membershipSuccessButOrderFailed:
    'Membership success but purchase of remaining failed.',
  paymentFailed: 'Payment failed',
  membershipInvoice: 'Membership Invoice',
  invoiceType: '{invoiceCount} Invoice - {invoiceType}',
  survey: 'Survey',
  commitOrder: 'Commit order',
  continueToPaymentMethod: 'Continue to Payment Method',
  continueToConfirm: 'Continue to Confirmation',
  thanksForYourOrder: 'Thanks for your order!',
  salesPersonWillContactYou:
    'A salesperson will be in touch shortly to discuss further.',
  orderSubmitSucces: 'Your 3D data order has been successfully submitted.',
  pointCloud3D: '3D Point Cloud',
  totalSurveyPrice: 'Total Survey price',
  data: 'Data',
  dataPayLater: 'Data (Pay later)',
  pricingTax: 'Pricing AUD, excludes GST.',
  larkiTermsAndCond: 'LARKI Customer Terms & Conditions apply.',
  termsAndCondApply: 'Customer Terms & Conditions apply.',
  contractAutoRenews: 'Contract auto-renews every 12 months.',
  continueToConfirmation: 'Continue to Confirmation',
  membershipFeaturesSummary:
    'Memberships include Data discounts, High-res 2D Map, File Manager, 3D Viewer, easy file sharing & more.',
  monthlyMembershipRenews:
    'Monthly Membership contract that auto-renews monthly.',
  annualMembershipRenews:
    'Annual Membership contract that auto-renews annually.',
  billed: 'Billed',
  continueProjectDetails: 'Continue to Project Details',
  assignedPayment: 'Assigned Payment',
  someoneElsePay: 'Someone else pay',
  payerName: "Payer's name",
  payerEmail: "Payer's email",
  enterName: 'Enter name',
  sendQuote: 'Send Quote',
  payerHasBeenSentQuote:
    '{payerName} has been sent this Quote to: {payerEmail}.',
  payerHasBeenSentRequest:
    '{payerName} has been sent request at: {payerEmail}.',
  whenPaidNotified: 'When paid, you will be notifed at: {userEmail}',
  quoteEmailed: 'Quote emailed!',
  requestEmailed: 'Request emailed!',
  quoteSentSuccessfully: 'Quote sent successfully',
  requestSentSuccessfully: 'Request sent successfully',
  totalPayNow: 'Total to pay now',
  needHelpContactUs: 'Need help? {contactUs}',
  assignedPaymentMessage:
    'Use the form below to email this Quote to someone for them pay.',
  sendToPayer: 'Send to Payer',
  trialExpiresIn: 'Trial expires in',
  annualContractMessage:
    'This is an annual contract that automatically renews annually, and payments will be charged to your primary card on the following billing cycle.',
  monthlyContractMessage:
    'This is a monthly contract that automatically renews monthly, and payments will be charged to your primary card on the following billing cycle.',
  upgradeEssentialsBenefits: 'Get online access to:',
  upgradeEssentialsMembership: 'Essentials Upgrade',
  startTrial: 'Start FREE trial',
  freeTrialHasStarted: 'Your 14 day free Trial has started ',
  backToAccount: 'Back to Account',
  yourTrialHasBegun: 'Your 14-day trial has begun.',
  //  Trial membership
  trialMembershipSummary:
    'You are on a trial of the Essentials Membership, an annual contract that automatically renews annually, and payments will be charged to your Payment Method on the following billing cycle. Your Membership will automatically start from the following Next Charge Date unless cancelled before.',
  essentialsMembershipTrial: 'Essentials Membership Trial',
  trialMembershipBenefits:
    'Try Essentials Membership for 14 days at no charge and enjoy great benefits including:',
  trialUpgrade: 'Trial upgrade',
  trial2DMapBenefit: 'High-res 2D Map (plane-captured)',
  essentialsDiscountBenefit: '30% off Aerial/Streetscape 3D Point Clouds',
  trialFileManagerBenefit: 'more storage in the File Manager',
  trial3DViewerBenefit: '3D Viewer',
  trialSupportBenefit: 'premium support',
  trialFreeTierBenefit: 'all the benefits of the Free tier',
  andMoreBenefit: 'and more',
  essentialsFileManagerBenefit1: 'download files and share projects',
  essentialsFileManagerBenefit2: 'upload and store project files (up to 100GB)',
  essentialsScanServicesDiscountBenefit:
    '10% of 3D Scan Capture Services, BIM and 2D Plans',
  essentials3DViewerDiscountBenefit1: 'navigate around 3D Point Clouds and BIM',
  essentials3DViewerDiscountBenefit2:
    'crop, section, measure, annotate in 3D Viewer',
  // Free tier benefits
  freeBenefitsMessage: 'You are on the Free tier for online access to:',
  free2DMapBenefit: 'create shapes, measure lengths/areas on the 2D Map',
  freeLotAreaBenefit: 'property lot areas',
  freeDiscountBenefit: '10% off Aerial/Streetscape 3D Point Clouds',
  freeFileBenefit: 'upload and store project files (up to 50GB)',
  freeDownloadFileBenefit: 'download files and share projects',
  freeShareProjectsBenefit: 'share projects',
  findOutMoreOnPage: 'Find out more on the {page}',
  membershipPage: 'Membership page',
  trialStartDate: 'Trial Start Date',
  nextChargeDate: 'Next Charge Date',
  essentialsMembershipMessage:
    'Your Essentials Membership contract will automatically start from the beginning of the Next Charge Date shown above, and payments will be processed from your Payment Method shown below, unless cancelled before.',
  receiveEmailBeforeTrialExpires:
    'You will receive an email one week before the trial expires.',
  invoicesToPay: 'Invoices to pay now:',
  change: 'Change',
  newProject: 'New Project',
  back: 'Back',
  trial: 'Trial',
  statusFilter: 'Status filter:',
  yourOrderSuccessfullyPaid: 'Your order has been successfully paid.',
  cancelMembership: 'Cancel Membership',
  areYouSureCancelMembership:
    'Are you sure you want to cancel your Membership? ',
  areYouSureCancelTrialMembership:
    'Are you sure you want to cancel your Trial Membership? ',
  keepMembership: 'Keep Membership',
  upgradeMembership: 'Upgrade Membership',
  cancelEssentialsMembershipBenefits:
    'If you cancel your Essentials Membership you will lose the following benefits:',
  cancelTrialMembershipBenefits:
    'If you cancel your Trial Membership you will lose the following benefits:',
  searchHere: 'Search here',
  commenced: 'Commenced',
  newOrder: 'New Order',
  addNewOrder: 'Add new Order',
  areYouSureDeleteProject:
    'Are you sure you want to delete project "{projectName}"?',
  deleteProject: 'Delete Project',
  deleteWarningMessage:
    'All the information associated with this Project, such as shapes, activities, files, etc., will be permanently deleted. You cannot undo this action.',
  confirmDeleteProject: 'Yes, delete Project',
  selectProject: 'Select project',
  selectJob: 'Select job',
  deleteProjectSuccess: 'Project "{projectName}" has been deleted.',
  searchProject: 'Search project',
  project: 'Project',
  pleaseSelectOrCreateProject:
    'Please type name to create project or select an existing project',
  pleaseTypeProjectName: 'Please type name to create project',
  projectsHaveMerged: 'The Projects have been merged.',
  mergeProjects: 'Merge Projects',
  selectProjectToMerge:
    'Select an existing Project to merge "{projectName}" into.',
  merge: 'Merge',
  mergeProjectNote:
    'I understand that "{projectName}" will inherit attributes of the Project it is merged into. This includes sharing to Project Users that may not currently have access. For any conflicts, the attribute of the Project being merged into will be kept. This cannot be undone.',
  mergeProjectsInfo:
    'Only projects where all jobs are in draft status can be merged together',
  referralBar: 'Refer LARKI to your friends and earn A$50',
  sendRequest: 'Send request',
  quoteAccepted: 'Quote accepted',
  error: 'Error',
  quoteId: 'Quote ID',
  amount: 'Amount',
  essentials: 'Essentials',
  larkiAdministrator: 'LARKI Administrator',
  larkiSupport: 'Support',
  government: 'Govt',
  downloadAll: 'Download All',
  activityFilter: 'Activity filter',
  orders: 'Orders',
  notes: 'Notes',
  uploads: 'Uploads',
  downloads: 'Downloads',
  statuses: 'Statuses',
  share: 'Share',
  statusUpdate: 'Status update',
  threeD: '3D',
  twoD: '2D',
  file: 'File',
  notifications: 'Notifications',
  freeTrialPlatform: '14 Day FREE Membership Trial',
  getFreeDataDiscount: 'Get ${amount} of FREE Data for your project',
  trialFeature2D: 'High-res 2D Map - aerial imagery of AU metros',
  trialFeature3D: '3D Viewer - measure point clouds & BIM online ',
  trialFeatureFileManager: 'File Manager -  track, upload & share with team',
  maybeLater: 'Maybe later',
  startDateTrial: 'Start Date of Trial',
  endDateTrial: 'End Date of Trial',
  upgrade: 'Upgrade',
  noCardDetailsRequired: 'No credit card details required!',
  sharePlatform: 'Share Platform',
  measureCollaboratePlatform: 'Measure & collaborate on the Platform',
  projectTrial3Users3Months: '3 Users for 3 Months Free Trial',
  projectTrialCongratulationsAccess:
    'Congrats! You plus two others, get free access to the following.',
  getProjectTrialFreeData: 'Get $49.50 of FREE Data for your project',
  projectUserNumber: `User {number}: {email}`,
  shareWithTheseUsers: 'Share with these users:',
  trialOfferExpired: 'Trial offer expired',
  sharePlatformWithTeam: 'Share access to the Platform with your team',
  proceedToShare: 'Proceed to Share',
  projectTrialSuccessModalHeader: 'The 3 month free Trial has started!',
  usersHaveAccessToPlatform: 'These users have Trial access to the Platform:',
  goToFileManager: 'Go to File Manager',
  invitationGroupProjectTrialTitle: `{daysLeft} days left on this project's Trials for these users:`,
  invitationGroupTrialTitle: 'Individual Trials for these users:',
  availableSpaces: 'Available spaces {spaces}',
  timeLeft: '{timeLeft} left',
  trialLengthExpiryAt: '{trialLength} trial expires {expiryAt}',
  upgradeToEssentials: 'For data discounts, upgrade to Essentials Membership:',
  dataDiscount165:
    'Get $165 worth of Streetscape &/or Aerial 3D Point Cloud Data for this project for FREE.',
  acceptOffer: 'Accept Offer',
  enterYourPromotionCode: 'Enter your Promotion Code:',
  trialExpiredAt: 'Trial expired {expiredAt}',
  new: 'New',
  similar: 'Similar',
  demoProjectOnly: 'Demonstration Project only',
  demoActionMessagePart1:
    'You cannot share, add notes or upload files to the "Demonstration Project".',
  demoActionMessagePart2:
    'Click the "Order 3D" button to get started on your own project.',
  closeAndContinue: 'Close and continue',
  deleteShapesWarning: 'Are you sure you want to delete these Shapes?',
  deleteShapesMessage:
    'All the information associated with these Shapes will be permanently deleted. You cannot undo this action.',
  streetscapePointCloudDescription:
    'Streetscape 3D Point Cloud - laser scans from a car',
  aerialPointCloudDescription:
    'Aerial 3D Point Cloud -  laser scans from a car',
  buyNowData: 'BUY NOW - Data in Database',
  getQuoteData: 'GET QUOTE - 3D Scan Capture Services',
  automaticDataAdvantages: 'fast, affordable',
  manualDataAdvantages: 'current, high-res',
  automaticDataDisadvantages: 'lower res, older',
  manualDataDisadvantages: 'slower, higher price',
  noCancel: 'No, cancel',
  yesDeleteShapes: 'Yes, delete shapes',
  layers: 'Layers',
  download2dShape: 'Download 2D shape',
  showShape: 'Show shape',
  hideShape: 'Hide shape',
  deleteShape: 'Delete shape',
  changeTypeInCart: 'Change type in cart',
  addToCart: 'Add to cart',
  guide: 'Guide',
  guideInstructions: `Create {emphasizeText1} on the Map.\n
  Shapes can be saved as a {emphasizeText2} (e.g. for master-planning).\n
  To make an Order: 
  a) Assign a {emphasizeText3} such as 3D Data in Database, Capture Services or Add-ons.
  b) Review products in the {emphasizeText4}.
  c) {emphasizeText5}, then follow the steps.`,
  streetscapePointCloudExplanation:
    '3D features seen from the street, output from a 3D laser scanner mounted on a car driving past.',
  aerialPointCloudExplanation:
    '3D features seen from ~3000m above, output from a 3D laser scanner mounted on a plane flying over.',
  exteriorPointCloudExplanation:
    '3D features seen from accessible footing, output from a 3D laser scanner mounted on a high tripod.',
  interiorPointCloudExplanation:
    '3D features seen from accessible floors, output from a 3D laser scanner mounted on a high tripod.',
  dronePointCloudExplanation:
    '3D features seen from ~40m above, output from a 3D laser scanner mounted on a drone flying over.',
  getQuoteCaptureServices: 'GET QUOTE - Capture Services',
  currentHighDetailPointClouds:
    'Current & high-detail Drone/Exterior/Interior 3D Point Clouds',
  unknownPointCloudExplanation:
    'Create shapes for length, perimeter and area measurements. Great for master-planning. 2D shapes can be downloaded.',
  buyNowDataInDatabase: 'BUY NOW - Data in the Database',
  buyNow3dDataInDatabase: '3D Data in the Database',
  buyNow3dDataInDatabaseDetails: 'Fast, affordable 3D Point Clouds',
  addOns: 'Add-ons',
  checkout: 'Checkout',
  startNewProject: 'Start New Project',
  removeFromCart: 'Remove from cart',
  duplicateShape: 'Duplicate shape',
  downloadAllFailed: 'Failed to download all',
  downloadAllUnavailable: 'No files to download',
  listProjectsFailed: 'Failed to list projects',
  buyNow: 'Buy Now',
  buyNow3dPhotoMesh: '3D Photo Mesh',
  buyNow3dPhotoMeshDetails: 'Textured, triangulated, 3D surface model',
  getQuote: 'Get Quote',
  getQuoteBim: 'BIM (Building Information Model)',
  getQuoteBimDetails: '3D terrain, walls, windows and more',
  getQuote2dFeaturePlan: '2D Feature Plan',
  getQuote2dFeaturePlanDetails: '2D drawing with survey details and levels ',
  getQuote3dScanCaptureServices: '3D Scan Capture Services',
  getQuote3dScanCaptureServicesDetails: 'Current, high-detail 3D Point Clouds',
  trialDiscount: 'Discount - 14-day Trial offer',
  nonMemberPrice: 'Non-Member Price',
  memberPriceDiscount: 'Member price:  {totalAmount}, save {saving}',
  preview: 'Preview',
  screenshot: 'Screenshot',
  hide: 'Hide',
  show: 'Show',
  perimeter: 'Perimeter',
  member: 'Member',
  saving: 'Saving...',
  forPrecision: 'For precision',
  higherCostThanDatabase: 'Higher cost than database',
  forConceptsAndContext: 'For concepts & context',
  addOnScan3D: '3D Scan Capture Services',
  addOnDatabase3D: '3D Data in the Database',
  addOnScanExplanation:
    '3D Point Clouds from 3D laser scanners mounted on Drones, Exterior Tripods, &/or Interior Tripods.',
  addOnDatabaseExplanation:
    '3D Point Clouds from 3D laser scanners mounted on planes (Aerial) & cars (Streetscape).',
  complete: 'Complete',
  tour2dStep1Title: 'Put in your project address',
  tour2dStep1:
    'Search an address central to your project to start creating shapes or scope areas for ordering 3D Data.',
  tour2dStep2Title: 'Shapes',
  tour2dStep2:
    'Use tools like Rectangle, Polygon, or Path to create shapes to use as scope areas to Order side data. The shapes can be saved as a Draft, to calculate lengths, areas, and labels, for say masterplanning your development.',
  tour2dStep3Title: '3D Data Types',
  tour2dStep3:
    'Once you have a Shape then assign a Type like Aerial, Streetscape, Drone, Exterior Tripod, or Interior Tripod, so that you can Order the 3D Data for your project.',
  tour2dStep4Title: 'Cart Toggle',
  tour2dStep4: 'With this button, you can show or hide your Cart.',
  tour2dStep5Title: 'Cart',
  tour2dStep5_1:
    'With this Cart, you will get a summary of the type of data, area, price, and turnaround time estimate - like a quote.',
  tour2dStep5_2:
    'The shapes will be saved to your dashboard, so you can share them with others or adjust them later.',
  tour2dStep5_3:
    'When you are ready to order, you can click on the Checkout button.',
  letsStart: "Let's start!",
  tours: 'Tours',
  getStarted2D: 'Order Data & 2D Maps',
  getStartedFileManager: 'File manager',
  getStarted3D: '3D Viewer',
  help: 'Help',
  getStartedPointerraHelp: "Pointerra's Manual",
  tourModal2dTitle: 'Want a tour of the 2D Map?',
  tourModal2dDescription: `From here, you will be able to search your Site Address and start 
    creating shapes over a Google Map, Satellite and High-res aerial imagery.`,
  tourModal2dDone: "You've finished the 2D Map tour.",
  tourModalFilemanagerTitle: 'Want a tour of the the File Manager?',
  tourModalFilemanagerDescription: `Easily manage your project files with an activity timeline and filters. 
    Check your order status and collaborate with your team.`,
  tourModalFilemanagerDone: "You've finished the File Manager tour.",
  congratulations: 'Congratulations',
  letsPlay: "Let's play!",
  tourFileManagerStep1Title: 'Activity Timeline',
  tourFileManagerStep1:
    'The activity timeline helps you keep track of who did what, and when, throughout your project.',
  tourFileManagerStep2Title: 'Filters',
  tourFileManagerStep2: `Quickly isolate any combination of orders, shares, notes, uploads, downloads, 
    status updates, and LARKI notifications.`,
  tourFileManagerStep3Title: 'Action Buttons',
  tourFileManagerStep3: `Use these buttons in your project's File Manager to Share, add Notes, Upload files, 
    Download All project files, and more.`,
  tourModal3dViewerTitle: 'Want a tour of the 3D Viewer?',
  tourModal3dViewerDescription: `Here you will be able to measure the 3D Point Clouds and BIM of your Projects.`,
  tourModal3dViewerDone: "You've finished the 3D Viewer tour.",
  tour3dViewerStep1Title: 'Datasets',
  tour3dViewerStep1: `Tick/untick the Datasets to turn them on/off including "Point cloud", "Base map/Terrain" etc.`,
  tour3dViewerStep2Title: 'Rectangle Clip Tool',
  tour3dViewerStep2: `Crop the point cloud and other 3D datasets horizontally, e.g. to isolate a building.`,
  tour3dViewerStep3Title: 'Height Clip Tool',
  tour3dViewerStep3: `Crop the point cloud and other 3D datasets at a specified height, e.g. to remove the roof and see inside.`,
  tour3dViewerStep4Title: 'Section Tool',
  tour3dViewerStep4: `View a vertical slice of the point cloud and other 3D files using a cross section.`,
  tour3dViewerStep5Title: 'Distance Measure Tool',
  tour3dViewerStep5_1: `Dimension the distance between 2 points.`,
  tour3dViewerStep5_2: `Press Shift to switch between 3D, XY horizontal, and Z vertical measuring modes.`,
  turnAroundTime: 'Turnaround: {eta} approx.',
  fullPrice: 'Full price: {totalAmount}',
  savingAmount: 'saved {saving}',
  getTourStatusFail: 'Failed to get tour status.',
  putTourStatusFail: 'Failed on saving for tour status.',
  shapesUnion: 'Shapes {union}Area: {shapesUnionArea}',
  productArea: 'Product Area',
  productAreaTotal: 'Product Area{delimiter} {productTotalArea}',
  shapeArea: 'Shape Area',
  area: 'Area',
  clickForMoreInfo: 'click for more info',
  subscribeButtonLabel: '{yearly}/yr or {monthly}/mth - Checkout',
  salesHelp: 'Sales help price: {amount}',
  age: 'Age: {dates}',
  zipping: 'Zip',
  downloading: 'Downloading',
  governmentFullWord: 'Government',
  startANewProject: 'Start a New Project',
  fileManagerInstructions:
    'Add a project by entering a site address on the 2D Map, before coming back to the File Manager.',
  fetching: 'Fetching',
  deleteShapesWarningAddOns:
    'Note: Add-ons associated ({addOns}) will also be deleted.',
  updateShapesWarning: 'Are you sure you want to change the Shape type?',
  updateShapesMessage:
    'All the information associated with these Shapes will be updated. You cannot undo this action.',
  yesChangeShapeType: 'Yes, change type',
  updateShapesWarningAddOns: 'Note: Add-ons associated ({addOns}) {caption}.',
  memberSaved: 'Member saved',
  yesRemove: 'Yes, remove',
  removeFromCartWarning: 'Are you sure you want to remove Shape from cart?',
  toDraftWarning: 'Are you sure you want to change Shape to draft?',
  navigateToRoute: `{navigation} to {routeName}`,
  quoted: 'Quoted',
  zoomExtents: 'Zoom to shape extents',
  alerts: 'Alerts',
  exteriorBimTooltip:
    'A Building Information Model, generated from Exterior Tripod 3D Point Clouds, of exterior features including terrain, walls, roofs, columns, window, doors.',
  interiorBimTooltip:
    'A Building Information Model, generated from Interior Tripod 3D Point Clouds, of interior features including floors, stairs, walls, ceilings, columns, window, doors.',
  exterior2dPlanTooltip:
    'A 2D drawing of the main exterior details and levels, generated from Exterior Tripod 3D Point Clouds, including terrain, walls, roofs, columns, window, doors.',
  interior2dPlanTooltip:
    'A 2D drawing of the main interior details and levels, generated from Interior Tripod 3D Point Clouds, including floors, stairs, walls, columns, window, doors.',
  architectural3DModel: '3D architectural model',
  bimFile: 'BIM file (RVT/IFC)',
  forAEC: 'For AEC existing docs',
  basicLOD2: 'Basic (LOD 2)',
  excFrames: 'Exc frames, fixtures...',
  takesTime: 'Takes time',
  lines2dText: '2D lines & text',
  pdfAndDwg: 'PDF & DWG',
  forApprovals: 'For approvals',
  basicBestFit: 'Basic, best-fit',
  singleCut: 'Single cut & sheet',
  BIMTooltip:
    '3D architectural models, generated from 3D Point Clouds, made up of features such as walls, columns, windows, doors and more.',
  FeaturePlanToolTip:
    '2D drawings of the main details and levels, generated from 3D Point Clouds, including walls, columns, windows, doors and more.',
  addTrial: 'Add trial',
  days: 'Days',
  viewAs: 'View as {as}',
  freeUser: 'Free User',
  trialUser: 'Trial User',
  governmentUser: 'Government User',
  hidden: 'Hidden',
  getQuoteAddOns: 'GET QUOTE - Add-ons',
  jpgScreenshot: '.jpg (screenshot)',
  linkToPageCopied: 'Link to this page copied!',
  copyingLinkToPage: 'Link to this page copying...',
  createNewBookmark: 'Create a new bookmark to this view?',
  openBookmarks: 'Open Bookmarks',
  grant: 'Grant',
  deny: 'Deny',
  _invoiceType: '{prefix} - {type}',
  dataInDatabase: 'Data in the Database',
  estimate: 'Estimate',
  discounts: 'DISCOUNTS',
  invoiceRfqNote:
    'This part is a Request For Quote (RFQ) to be confirmed by a LARKI representative. You will receive an email regarding progress of this RFQ. Feel free to {contact} for further information.',
  tbcBuyNowDataInDatabase: 'TBC Data in the Database',
  youNeedAccess: 'You need access',
  accessRequestSent: 'Access request sent.',
  accessRequestEmail:
    'You will receive an email when your request has been granted.',
  profileRequestingAccess: 'Your profile requesting access:',
  youNeedToBeInvited:
    'You need to be invited or granted access to view this project.',
  requestAccess: 'Request Access',
  accessDenied: 'Access Denied',
  accessDeniedDescription:
    'Sorry, your request to view this page has been denied. Please contact an admin or a user with relevant privileges to resolve this issue.',
  hasRequestedAccess: '{fullName} ({email}) has requested access to:',
  projectAddress: 'Project address',
  new3dBookmark: 'Create a new Bookmark to this view?',
  disabledPaymentScreenForSupportAlert:
    "We're sorry, but you cannot proceed to the next step due to the limitations of your 'Support' account",
  rejectOrAcceptAlert:
    "You have {status} {email}'s request to access this page.",
  dataInTheDatabaseDiscount: 'Data in Database Discount ({discount}%, inc GST)',
  captureServicesDiscount: 'Capture Services Discount ({discount}%, inc GST)',
  addOnsDiscount: 'Add-ons Discount ({discount}%, inc GST)',
  memberPriceIncGst: 'Member Price (inc GST)',
  membersSave: 'Members save',
  gst: 'GST',
  inAppSelfServeDiscount: 'In-app Self-service Discount ({discount}%, in GST)',
  fullPriceWithSalesHelpInGst: 'Full Price with Sales Help (inc GST)',
  quote: 'Quote',
  projectId: 'Project ID',
  orderId: 'Order ID',
  downloaded: 'downloaded',
  orderSubjectAgreement: "Order is subject to LARKI's {link}",
  dataType: 'Data type',
  approxInitialEstimate: 'Approx Initial Estimate:',
  approxDiscounts: 'Approx Discounts',
  approxGst: 'Approx GST',
  approxInitialEstimateTotal: 'Approx Initial Estimate Total TBC',
  rfqNote:
    'This request for quote (RFQ) is to be confirmed (TBC) by a LARKI representative.',
  paymentReceived: 'Payment Received',
  viewPaymentReceipt: 'View Payment Receipt',
  requestForQuote: 'Request for Quote',
  buyItems: '{title} items:',
};

module.exports = messages;
